/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {
    // store the slider in a local variable


    // tiny helper function to add breakpoints
    function getGridSize() {
        return (window.innerWidth < 600) ? 1 :
            (window.innerWidth < 850) ? 1 : 2;
    }
    function getGridSizePartners() {
        return (window.innerWidth < 480) ? 1 :
            (window.innerWidth < 850) ? 2 : 6;
    }

    // Breakpoints (to match up with variables.scss)
    var screenMdMin = 1024,
        screenSmMin = 850,
        screenXxsMin = 480;

    // Use this variable to set up the common and page specific functions. If you
    // rename this variable, you will also need to rename the namespace below.
    var Sage = {
        // All pages
        'common': {
            init: function() {
                new Swiper(".adverts-inactive-swiper", {
                    breakpoints: {
                        0: {
                            slidesPerView: 1.25,
                            spaceBetween: 30
                        },
                        576: {
                            slidesPerView: 3,
                            spaceBetween: 30
                        },
                        1250: {
                            slidesPerView: 4,
                            spaceBetween: 30,
                        }
                    }
                });

                new Swiper(".adverts-active-swiper", {
                    breakpoints: {
                        0: {
                            slidesPerView: 1,
                            spaceBetween: 30
                        },
                        850: {
                            slidesPerView: 2,
                            spaceBetween: 30
                        },
                        992: {
                            slidesPerView: 3,
                            spaceBetween: 30
                        }
                    }
                });

                if($('#countdown-timer-container').length) {
                    var dateData = document.getElementById("countdown-timer-container").getAttribute('data-date');
                    var countDownDate = new Date(Date.parse(dateData)).getTime();
                    // Update the count down every 1 second
                    var x = setInterval(function () {
                        // Get today's date and time
                        var now = new Date().getTime();

                        // Find the distance between now and the count down date
                        var distance = countDownDate - now;

                        // Time calculations for days, hours, minutes and seconds
                        var days = Math.floor(distance / (1000 * 60 * 60 * 24));
                        var hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
                        var minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
                        var seconds = Math.floor((distance % (1000 * 60)) / 1000);
                        // Display the result in the element with id="demo"
                        document.getElementById("countdown-timer").innerHTML = "<div class='value-wrapper'><div class='value'>" + days + "</div><div class='title'>days</div></div><div class='value-wrapper'><div class='value'>" + hours + "</div><div class='title'>hours</div></div><div class='value-wrapper'><div class='value'>" + minutes + "</div><div class='title'>mins</div></div>";
                        // If the count down is finished, write some text
                        if (distance < 0) {
                            clearInterval(x);
                            document.getElementById("countdown-timer").innerHTML = "EXPIRED";
                        }
                    }, 1000);
                }

                $('.search-toggle').on('click', function(){
                   $('.custom-search-form').toggleClass('active');
                   $('.toggle-icon').toggleClass('fa-search fa-times');
                });

                $('.hamburger-menu-toggle').on('click', function(){
                    $('.hamburger-menu').toggleClass('active');
                });

                $('.hamburger-menu .close-menu .inner').on('click', function(){
                    $('.hamburger-menu').removeClass('active');
                });

                // JavaScript to be fired on all pages
                var $window = $(window),
                    upcomingSlider,
                    partnersSlider,
                    tertiaryNewsSlider;

                $('body').addClass('js');
                // Body classes added based on conditional comments.
                if ($('.isIE').length > 0) {
                    $('body').addClass('ie');
                }
                if ($('.ie8').length > 0) {
                    $('body').addClass('ie8');
                    $('.ie8.hidden').remove();
                }
                if ($('.ie9').length > 0) {
                    $('body').addClass('ie9');
                    $('.ie9.hidden').remove();
                }

                $('.person').matchHeight();

                // Strip img width and height attributes from IE8
                $('body.ie8 img').removeAttr('height').removeAttr('width');


                // For iOS autoscroll on textfield focus
                var iOS = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
                //console.log(iOS);


                iniScrollToFixEls = function() {
                    // Make sure STF is off before ini (is in a short timeOut to make sure iOS triggers it on rotate).
                    setTimeout(function() {
                        $('header.banner, .wrap.container-fluid').removeAttr('style');
                        $('header.banner').trigger('detach.ScrollToFixed');
                        $('.side-bar .item .teams').trigger('detach.ScrollToFixed').css('margin-top', '');

                        if ($(window).outerWidth() > screenMdMin) {

                            $('header.banner').scrollToFixed({
                                    spacerClass: 'scrollToFixed-spacer'
                                }
                                /*{
                                                        marginTop:$('.top-nav').outerHeight(),
                                                        preFixed: function() { $('.top-nav').css('position','fixed'); },
                                                        preUnfixed: function() {$('.top-nav').css('position',''); }
                                                    }*/
                            );

                            teamsBlock = $('.side-bar .item .teams').outerHeight();
                            teamNames = $('.side-bar .item .teams .team').outerHeight();
                            headerBlock = $('header.banner').outerHeight();

                            teamsMtop = (teamsBlock - teamNames) + headerBlock;

                            $('.side-bar .item .teams').scrollToFixed({
                                spacerClass: 'scrollToFixed-side-bar-spacer',
                                //limit: $('.data-visualisation').offset().top <--- doesn't work properly, don't know why
                            });
                        }
                    }, 100);
                };
                iniScrollToFixEls();

                checkHeaderMob = function() {
                    setTimeout(function() {
                        if ($(window).outerWidth() < screenMdMin + 1) {
                            $('body >.wrap.container-fluid').css('margin-top', $('header.banner').outerHeight() - parseInt($('nav.nav-primary').css('margin-bottom')));
                        } else {
                            $('body >.wrap.container-fluid').css('margin-top', '');
                        }
                    }, 255);
                };
                checkHeaderMob();

                var headerhi = $('header.banner').outerHeight();



                $('.menu-item-has-children .sub-menu').before('<a data-target=".my-dropdown" class="my-dropdown-toggle"><span class="fa fa-chevron-down"></span></a>');
                $('.my-dropdown-toggle').on('click', function() {
                    tgt = $(this).attr('data-target');
                    // console.log('tgt= '+tgt);
                    toggle = 1;
                    if (tgt === "#searchform") {
                        if (iOS === true) {
                            $(window).scrollTop(0);
                        }

                        toggle = 0;
                        searchTop = $('header.banner .top-nav').outerHeight(true) - 3;
                        searchTopAlt = $('header.banner .top-nav>.row').outerHeight(true);
                        if (searchTopAlt > searchTop) {
                            searchTop = searchTopAlt;
                        }
                        //-($('#main-nav').outerHeight()/2) ;//3+$('#main-nav').offset().top-($('#main-nav').outerHeight()/2);
                        $(tgt).css('top', searchTop);
                        setTimeout(function() { $('#searchform input[type="text"]').focus(); }, 250);
                    }
                    if (tgt === '.my-dropdown') {
                        tgt = $(this).closest('.menu-item-has-children');
                    } else {
                        tgt = $(tgt);
                    }
                    if (toggle === 1) {
                        tgt.toggleClass('open');
                    } else {
                        tgt.addClass('open');
                    }
                });

                /*$('.dropdown-toggle').on('click',function(){
                    $(this).next('ul').toggle();
                });*/

                miniHeader = function() {
                    if ($('.triggerbox').length > 0) {
                        ylimit = ($('.triggerbox').offset().top - headerhi) - $(window).scrollTop();
                        if (ylimit < -5) {
                            $('header.banner, .side-bar .item .teams').addClass('mini');
                            $("#searchform").css('top', '').removeClass('open');
                        } else {
                            if ($('header.banner').hasClass('mini')) {
                                $("#searchform").css('top', '').removeClass('open');
                                $('header.banner, .side-bar .item .teams').removeClass('mini');
                            }

                        }
                    }
                };
                miniHeader();

                // Check news panel heights
                fixHeights = function() {
                    $('.main-news-wrapper>.news-panel:first-child, .main-news-wrapper>.news-panel.narrow').css('min-height', '');

                    mainNewsHeight = $('.main-news-wrapper>.news-panel:first-child').outerHeight();
                    mainNewsHeightB = $('.main-news-wrapper>.news-panel.narrow').outerHeight();

                    if (mainNewsHeight < mainNewsHeightB) {
                        mainNewsHeight = mainNewsHeightB;
                    }
                    if ($(window).outerWidth() > screenSmMin) {
                        $('.main-news-wrapper>.news-panel:first-child, .main-news-wrapper>.news-panel.narrow').css('min-height', mainNewsHeight);
                    } else {
                        $('.main-news-wrapper>.news-panel:first-child, .main-news-wrapper>.news-panel.narrow').css('min-height', '');
                    }


                    $('.dfps>div>div').css('min-height', '');
                    dfp = $('.dfps>div').eq(0).outerHeight();
                    dfpB = $('.dfps>div').eq(1).outerHeight();
                    dfpC = $('.dfps>div').eq(2).outerHeight();

                    if (dfp < dfpB) {
                        dfp = dfpB;
                    }
                    if (dfp < dfpC) {
                        dfp = dfpC;
                    }
                    if ($(window).outerWidth() > screenSmMin) {
                        $('.dfps>div>div').css('min-height', dfp);
                    } else {
                        $('.dfps>div>div').css('min-height', '');
                    }


                };

                // Fix padding on dfps (for sidebar).
                dfpHeightFix = function() {
                    sbWidth = $('.side-bar').outerWidth();
                    dfpWidth = 300;
                    dfpHeight = 250;
                    dfpPadding = (sbWidth - dfpWidth) / 2;
                    dfpNewHeight = 250 + (sbWidth - dfpWidth);
                    if (dfpNewHeight > dfpHeight + 50) {
                        dfpNewHeight = dfpHeight + 50;
                    }

                    $('.side-bar .dfp-wrapper').css({ 'height': dfpNewHeight });
                };


                $('#mixContainer').mixItUp();

                $('.filter').on('click', function(e) {
                    e.preventDefault();
                });

                dfpHeightFix();
                fixHeights(); // For home page news and container for 'dfps'

                $(window).resize(function() {
                    //console.log('resize');
                    iniScrollToFixEls();
                    checkHeaderMob();
                    dfpHeightFix();
                    fixHeights();

                    var gridSize = getGridSize(),
                        partnerGetGridSizePartners = getGridSizePartners();
                    if(upcomingSlider !== undefined){
                        if (upcomingSlider.length > 0) {
                            upcomingSlider.data('flexslider').vars.minItems = gridSize;
                            upcomingSlider.data('flexslider').vars.maxItems = gridSize;
                        }
                    }
                    if(partnersSlider !== undefined){
                        if (partnersSlider.length > 0) {
                            partnersSlider.data('flexslider').vars.minItems = partnerGetGridSizePartners;
                            partnersSlider.data('flexslider').vars.maxItems = partnerGetGridSizePartners;
                        }
                    }
                    if(tertiaryNewsSlider !== undefined){
                        if (tertiaryNewsSlider.length > 0) {
                            tertiaryNewsSlider.data('flexslider').vars.minItems = partnerGetGridSizePartners;
                            tertiaryNewsSlider.data('flexslider').vars.maxItems = partnerGetGridSizePartners;
                        }
                    }

                    //  $("#searchform").css('top','').removeClass('open');

                });
                var waiiit = 0; // setup var to stop timeout getting set repeatedly.
                $(window).scroll(function() {
                    // $("#searchform").css('top','').removeClass('open');
                    if ($('.triggerbox').length > 0) {

                        if (waiiit !== 1) {
                            setTimeout(function() {
                                //headerhi=$('header.banner').outerHeight();

                                miniHeader();
                                waiiit = 0;
                            }, 350);
                            waiiit = 1;
                        }
                    }

                    checkHeaderMob();



                    teamsBlock = $('.side-bar .item .teams').outerHeight();
                    teamNames = $('.side-bar .item .teams .team-info').outerHeight();
                    teamNamesMargins = $('.side-bar .item .teams .team-info').outerHeight(true);
                    marg = teamNamesMargins - teamNames;
                    headerBlock = $('header.banner').outerHeight();


                    if ($('.side-bar .item .teams').hasClass('scroll-to-fixed-fixed')) {
                        $('.side-bar .item .teams.scroll-to-fixed-fixed').css('margin-top', ((teamsBlock - headerBlock) * -1) + teamNames - marg);
                    } else {
                        $('.side-bar .item .teams').css('margin-top', '');
                    }
                    if ($(window).scrollTop() < 1) {
                        $('.side-bar .item .teams').css('margin-top', '');
                    }
                });

                //$('.carousel').carousel({interval: false, wrap: false});

                var myItemWidth = $('.flexslider>ul>li:first-child').outerWidth(),
                    partnerItemWidth = $('.home .partners--home>ul>li:first-child').outerWidth(),
                    tertiaryNewsItemWidth = $('.home .tertiary-news--home>ul>li:first-child').outerWidth();


                $('.home .tertiary-news--home').flexslider({
                    animation: "slide",
                    animationLoop: true,
                    controlNav: false,
                    itemWidth: tertiaryNewsItemWidth,
                    itemMargin: 20,
                    minItems: getGridSizePartners(),
                    maxItems: getGridSizePartners(),
                    move: 1,
                    slideshow: false,
                    customDirectionNav: $(".tertiary-news-nav a")
                });
                tertiaryNewsSlider = $('.home .tertiary-news--home');

                $('.home .partners--home').flexslider({
                    animation: "slide",
                    animationLoop: true,
                    controlNav: false,
                    itemWidth: partnerItemWidth,
                    itemMargin: 20,
                    minItems: getGridSizePartners(),
                    maxItems: getGridSizePartners(),
                    move: 1,
                    slideshow: true,
                    slideshowSpeed: 6000,
                    customDirectionNav: $(".partners-nav a")
                });
                partnersSlider = $('.home .partners--home');

                $('.home .flexslider').flexslider({
                    animation: "slide",
                    animationLoop: false,
                    slideshow: false,
                    customDirectionNav: $(".match-nav a")
                });


                $('.upcoming-matches .flexslider').flexslider({
                    animation: "slide",
                    animationLoop: false,
                    itemWidth: myItemWidth,
                    itemMargin: 5,
                    minItems: getGridSize(),
                    maxItems: getGridSize(),
                    move: 1,
                    slideshow: false,
                    customDirectionNav: $(".match-nav a")
                });
                upcomingSlider = $('.upcoming-matches .flexslider');


                // Twitter widget
                if ($('#twitterBox').length > 0) {
                    el = $('#twitterBox');
                    twitterHandle = el.attr('data-handle');
                    el.append('<div id="twitterFetcher"></div>');
                    var configProfile = {
                        "profile": { "screenName": twitterHandle },
                        "domId": 'twitterFetcher',
                        "maxTweets": 20,
                        "enableLinks": true,
                        "showUser": true,
                        "showTime": true,
                        "showImages": false,
                        "showRetweet": false,
                        "lang": 'en'
                    };
                    twitterFetcher.fetch(configProfile);

                    $('#twitterFetcher').before('<div class="twitter-header"><span>Tweets</span> by <a href="http://twitter.com/' + twitterHandle + ' target="_blank"">@' + twitterHandle + '</a></div>');
                    $('#twitterFetcher').after('<div class="twitter-footer"><a href="http://twitter.com/' + twitterHandle + '" target="_blank">View on Twitter</a></div>');
                }

                //add our parent class to all the items
                $('.mega-menu-item-type-post_type').each(function(index) {
                    $( this ).find('.mega-menu-link').addClass('parent-link');
                });

                $('.mega-sub-menu').each(function(index) {
                    $( this ).find('.mega-menu-link').removeClass('parent-link').addClass('child-link');
                });

            },
            finalize: function() {
                // JavaScript to be fired on all pages, after page specific JS is fired
            }
        },
        // Home page
        'home': {
            init: function() {
                // JavaScript to be fired on the home page


            },
            finalize: function() {
                // JavaScript to be fired on the home page, after the init JS
            }
        },
        // About us page, note the change from about-us to about_us.
        'about_us': {
            init: function() {
                // JavaScript to be fired on the about us page
            }
        }
    };

    // The routing fires all common scripts, followed by the page specific scripts.
    // Add additional events for more control over timing e.g. a finalize event
    var UTIL = {
        fire: function(func, funcname, args) {
            var fire;
            var namespace = Sage;
            funcname = (funcname === undefined) ? 'init' : funcname;
            fire = func !== '';
            fire = fire && namespace[func];
            fire = fire && typeof namespace[func][funcname] === 'function';

            if (fire) {
                namespace[func][funcname](args);
            }
        },
        loadEvents: function() {
            // Fire common init JS
            UTIL.fire('common');

            // Fire page-specific init JS, and then finalize JS
            $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
                UTIL.fire(classnm);
                UTIL.fire(classnm, 'finalize');
            });

            // Fire common finalize JS
            UTIL.fire('common', 'finalize');
        }
    };

    // Load Events
    $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
